import { Address, isAddress as viemIsAddress } from 'viem';
import { BOX_TLDS, FREENAME_TLDS, UNSTOPPABLE_TLDS } from '@frontend/common';

// config
const ALL_TLDs = [...BOX_TLDS, ...UNSTOPPABLE_TLDS, ...FREENAME_TLDS, 'eth'];

export namespace Identity {
  export type IdentityType = 'yodl' | 'address' | 'unstoppable' | 'ens' | 'box';

  export const parseType = (needle?: string) => {
    if (needle == undefined) return undefined;

    const identityCheckers = {
      address: isAddress,
      yodl: isYodl,
      ens: isEns,
      unstoppable: isUnstoppable,
      box: isBox,
      freename: isFreename,
    };

    for (const [type, checker] of Object.entries(identityCheckers)) {
      if (checker(needle)) {
        return type as IdentityType;
      }
    }

    return undefined;
  };

  // Splits the last part of the identifier into the TLD (vitalik.eth => vitalik, eth) or (vitalik.crypto.eth => vitalik.crypto, eth)
  // TODO: Currently, the system only checks against the TLDs in the ALL_TLDs array. It would be more useful to check against a specific set of TLDs. This way, it would only fetch data for valid TLDs.
  export const parseIdentifier = (_identifier: string) => {
    const identifier = _identifier.trim();
    if (!identifier) return { identifier: '', tld: '' };

    if (!identifier.includes('.')) return { identifier, tld: '' };

    const parts = identifier.split('.');
    const tldCandidate = parts.pop() || '';
    const baseIdentifier = parts.join('.');

    const tld = ALL_TLDs.includes(tldCandidate) ? tldCandidate : '';

    return { identifier: baseIdentifier, tld };
  };

  //
  // Identity checkers
  //

  export const isYodl = (needle: string) => {
    // Prevent people creating yodl usernames that are syntactically wallet addresses.
    // - A user could pick as username: 0x12323213 and overwrite that actual wallet address.
    // - TODO: fine-tune so this allows for non valid 0x addresses. e.g. `0xfoobar`
    return !needle.startsWith('0x') && !needle.includes('.');
  };

  export const isAddress = (needle?: string): needle is Address => {
    if (needle == undefined) return false;

    return viemIsAddress(needle);
  };

  export const isUnstoppable = (needle: string) => {
    const needleLower = needle.toLowerCase();
    return UNSTOPPABLE_TLDS.some((tld) => needleLower.endsWith(`.${tld}`));
  };

  export const isEns = (needle: string) => {
    return needle.toLowerCase().endsWith('.eth');
  };

  export const isBox = (needle: string) => {
    const needleLower = needle.toLowerCase();
    return BOX_TLDS.some((tld) => needleLower.endsWith(`.${tld}`));
  };

  export const isFreename = (needle: string) => {
    const needleLower = needle.toLowerCase();
    return FREENAME_TLDS.some((tld) => needleLower.endsWith(`.${tld}`));
  };
}
