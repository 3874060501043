import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { FiatCurrency } from '@frontend/common/constants/currency';

interface UserSettings {
  preferredCurrency: FiatCurrency;
  preferredPrivacy: 'public' | 'private';
}

interface UserSettingsStore extends UserSettings {
  setPreferredCurrency: (currency: FiatCurrency) => void;
  setPreferredPrivacy: (privacy: 'public' | 'private') => void;
}

export const useUserSettingsStore = create<UserSettingsStore>()(
  persist(
    (set) => ({
      preferredCurrency: FiatCurrency.USD,
      preferredPrivacy: 'public',
      setPreferredCurrency: (currency) => set({ preferredCurrency: currency }),
      setPreferredPrivacy: (privacy) => set({ preferredPrivacy: privacy }),
    }),
    {
      name: 'user-settings-storage',
    },
  ),
);
