'use client';

import { FullScreenDialog } from '@frontend/components/FullScreenDialog';
import { QrScanSection } from '@frontend/components/QrScanSection';
import AppContainer from '@frontend/components/ui/AppContainer';
import UserSettingsSection from '@frontend/components/UserSettingsSection';
import WalletConnectSection from '@frontend/components/WalletConnectSection';
import logoDark from '@frontend/public/assets/images/logo.svg';
import { cn } from '@frontend/utils/tailwindUtil';
import { GearIcon } from '@radix-ui/react-icons';
import {
  AspectRatio,
  Badge,
  Button,
  Flex,
  IconButton,
  Separator,
  Skeleton,
  Text,
} from '@radix-ui/themes';
import { Scanner } from '@yudiel/react-qr-scanner';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { MdQrCode } from 'react-icons/md';
import { useAccount } from 'wagmi';

export function Header() {
  const { isConnected } = useAccount();

  const [dialogState, setDialogState] = useState({
    settings: false,
    wallet: false,
    scanQRCode: false,
  });

  const toggleDialog = (dialogType: 'settings' | 'wallet' | 'scanQRCode') => {
    setDialogState((prev) => ({
      ...prev,
      [dialogType]: !prev[dialogType],
    }));
  };

  // If user logs out, we should reset the dialog state
  useEffect(() => {
    setDialogState({
      settings: false,
      wallet: false,
      scanQRCode: false,
    });
  }, [isConnected]);

  return (
    <>
      <Flex
        px="2"
        py="3"
        justify="between"
        align="center"
        className={cn('shadow-sm')}
        style={{ background: 'var(--accent-0' }}
      >
        <Flex gap="1" align="center">
          <Link href="/go" passHref>
            <Image src={logoDark} alt="Yodl Logo" width={70} priority />
          </Link>
          <Badge variant="soft" size="1">
            beta
          </Badge>
          <IconButton
            ml="3"
            variant="ghost"
            onClick={() => toggleDialog('scanQRCode')}
            className="lg:!hidden"
          >
            <MdQrCode className="size-5" />
          </IconButton>
        </Flex>

        {isConnected ? (
          <IconButton
            variant="ghost"
            className="!m-0"
            size="2"
            onClick={() => toggleDialog('settings')}
          >
            <GearIcon className="size-5" />
          </IconButton>
        ) : (
          <Button
            variant="outline"
            size="3"
            onClick={() => toggleDialog('wallet')}
          >
            Connect
          </Button>
        )}
      </Flex>

      <Separator orientation="horizontal" size="4" />

      <FullScreenDialog
        open={dialogState.wallet}
        addDesktopPadding
        onOpenChange={(open) =>
          setDialogState((prev) => ({ ...prev, wallet: open }))
        }
        title="Connect Wallet"
      >
        <WalletConnectSection />
      </FullScreenDialog>

      <FullScreenDialog
        open={dialogState.settings}
        addDesktopPadding
        onOpenChange={(open) =>
          setDialogState((prev) => ({ ...prev, settings: open }))
        }
        title={isConnected ? 'Settings' : 'Payment Preferences'}
      >
        <UserSettingsSection
          handleOnClose={() => {
            setDialogState((prev) => ({ ...prev, settings: false }));
          }}
        />
      </FullScreenDialog>

      <FullScreenDialog
        open={dialogState.scanQRCode}
        addDesktopPadding
        onOpenChange={(open) => {
          setDialogState((prev) => ({ ...prev, scanQRCode: open }));
        }}
        title="Scan QR Code"
      >
        <QrScanSection onClose={() => toggleDialog('scanQRCode')} />
      </FullScreenDialog>
    </>
  );
}
