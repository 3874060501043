import { AspectRatio, Button, Flex, Skeleton, Text } from '@radix-ui/themes';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

interface QrScanSectionProps {
  onClose: () => void;
}

export function QrScanSection({ onClose }: QrScanSectionProps) {
  const router = useRouter();
  const [isQrAccessLoading, setIsQrAccessLoading] = useState(false);
  const [qrCodeError, setQrCodeError] = useState<string | null>(null);
  const [isAllowedToScan, setIsAllowedToScan] = useState(false);

  const requestCameraAccess = useCallback(async () => {
    setIsQrAccessLoading(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { exact: 'environment' },
        },
      });

      // Stop the stream immediately as we just need to check access
      stream.getTracks().forEach((track) => track.stop());

      setIsAllowedToScan(true);
    } catch (error) {
      console.error('Camera access error:', error);
      setIsAllowedToScan(false);
    }
    setIsQrAccessLoading(false);
  }, []);

  useEffect(() => {
    requestCameraAccess();
    return () => {
      setIsAllowedToScan(false);
    };
  }, [requestCameraAccess]);

  return (
    <Flex mt="9" direction="column" gap="4" align="center">
      <AspectRatio ratio={1} className="rounded-3xl overflow-hidden">
        {isAllowedToScan ? (
          <Scanner
            onScan={(result) => {
              if (Array.isArray(result) && result.length > 0) {
                const url = result[0].rawValue;
                setQrCodeError(null);
                if (url.startsWith('https://')) {
                  const currentOrigin = window.location.origin;
                  const scannedOrigin = new URL(url).origin;

                  if (scannedOrigin === currentOrigin) {
                    router.push(url);
                    onClose(); // Close the dialog after successful scan
                  } else {
                    setQrCodeError(
                      'Scanned QR code is not from the current origin',
                    );
                  }
                } else {
                  setQrCodeError('Invalid or unsafe URL detected');
                }
              }
            }}
            onError={(error) => {
              if (error instanceof Error) {
                setQrCodeError(error.message);
              } else {
                setQrCodeError('An unknown error occurred');
              }
              setIsQrAccessLoading(false);
              setIsAllowedToScan(false);
            }}
            constraints={{
              facingMode: { exact: 'environment' },
            }}
          />
        ) : (
          <Flex justify="center" align="center" className="w-full h-full">
            <Skeleton className="w-3/4 h-3/4" />
          </Flex>
        )}
      </AspectRatio>

      {!isAllowedToScan ? (
        <Button
          radius="full"
          loading={isQrAccessLoading}
          onClick={requestCameraAccess}
        >
          Request Camera Access
        </Button>
      ) : (
        <Text>Scan to send crypto</Text>
      )}

      {qrCodeError && <Text color="red">{qrCodeError}</Text>}
    </Flex>
  );
}
